import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import axios from 'axios';

function App() {

  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    const getCsrfToken = async () => {
      try {
      
        const response = await axios.get('https://aws.pftpedu.org/api/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.token);
      } catch (error) {
        console.error('CSRF token fetch failed', error);
      }
    };

    getCsrfToken();
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        
       <iframe src={'https://onsite-portal.niais.org/'} title='ok' height={720} width={'100%'}></iframe>
      </header>
    </div>
  );
}

export default App;
